



















































#q-app{
  height: 100%;
}
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ddd;
  &__left{
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__center{
    display: flex;
    align-items: center;
    height: 56px;
    &__text{
      font-family: Spoqa Han Sans Neo;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
    }

  }
  &__right{
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 1079px) {
  /* For mobile: */
  .is-desktop-show{
    display: none !important;
  }
}

@media only screen and (min-width: 1080px) {
  /* For desktop: */
  .is-mobile-show{
    display: none !important;
  }
}

